<template>
  <div>
    <nav-header @mediachangedata="mediahandler"></nav-header>
    <div class="envCon" :class="lang">
      <div class="media">
        <div class="video feature">
          <h4>{{$t('media.videotitle')}}</h4>
          <ul>
            <li v-for="(item,index1) in zh_data.videoList" :key="index1">
              <a :href="item.resource" target="_blank">
                <div class="video-container">
                  <img  :src="item.bannerList.length>0 ? item.bannerList[0].url:''" alt="">
                  <div class="video-icon">
                    <img src="/img/media/video-icon.png" alt="">
                  </div>
                </div>
                <span>{{item.title}}</span>
                <p>{{item.date}}</p>
              </a>
            </li>
          </ul>

        </div>
        <div class="news feature">
          <h4 style="padding-bottom: 0">{{$t('media.newstitle')}}</h4>
          <ul>
              <li v-for="(item,index) in zh_data.newLists" :key="index" @click="toDetail(index)">
                  <img  v-lazy="item.bannerList.length>0 ? item.bannerList[0].url : ''" alt="">
                  <span>{{item.title}}</span>
                  <p>{{item.date}}</p>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import NavHeader from '../components/Header'
import {mapState} from 'vuex'
import { getdataInfo } from "../api/home";
export default {
  name: "Media",
  components:{
    NavHeader
  },
  data() {
    return{
      id:6,
      pageType: 'media',

      videoList:[
        {
          url:'https://v.qq.com/x/page/f3250nzg8o8.html',
          imgurl:'/img/media/video1.JPG',
          title:'潘石屹：希望每个孩子都成为人格高贵的人',
          date:'2021/6/3'
        },
        {
          url:'https://v.qq.com/x/page/r30493qzayh.html',
          imgurl:'/img/media/video2.jpg',
          title:'天水养正幼儿园建成啦！',
          date:' 2020/1/10'
        },
        {
          url:'https://v.qq.com/x/page/s3259er7wzv.html',
          imgurl:'/img/media/video3.jpg',
          title:'潘石屹：让正义护佑我们',
          date:'2020/1/9'
        }
      ],
      envideoList:[
        {
          url:'https://v.qq.com/x/page/f3250nzg8o8.html',
          imgurl:'/img/media/video1.JPG',
          title:'Pan Shiyi: From a Child to a Man, Hope a Noble Character Burgeons and Thrives',
          date:'June 2021'
        },
        {
          url:'https://v.qq.com/x/page/r30493qzayh.html',
          imgurl:'/img/media/video2.jpg',
          title:'Tian Shui Yang Zheng Kindergarten Is Completed!',
          date:' January 2020'
        },
        {
          url:'https://v.qq.com/x/page/s3259er7wzv.html',
          imgurl:'/img/media/video3.jpg',
          title:'Pan Shiyi: May Righteousness Shield Us',
          date:'January 2020'
        }
      ],
      zh_data:{},
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
      date:''
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },
  computed:{
    ...mapState({
      lang:state =>state.lang
    })
  },
  //query: {content: JSON.stringify(this.zh_data.newLists[index])} // 参数传值
  methods: {
    toDetail (index) {
      this.$router.push({
        name: 'detail',
        path:'/detail',
        // query:{article: item}
       query:{id:index}
      })
    },
    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
    
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      zh_envdata.videoList.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      zh_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
       //zh_envdata.newLists = zh_envdata.newLists.reverse()
      const en_envdata= JSON.parse(data.esgContent.enContent)
      en_envdata.videoList.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      en_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      //en_envdata.newLists=en_envdata.newLists.reverse()
      if(this.$i18n.locale=='zh'){
        this.zh_data = zh_envdata
      }else {
        this.zh_data = en_envdata
      }
    },

    mediahandler(){
      this.getdata()
    }
  }
}
</script>
<style>
.wscnph{
  display: block;
  width: 100%;
}
</style>
<style scoped lang="scss">
.media{
  padding: 80px 0;
  margin: 0 auto;
}

.media h4{
  font-size: 36px;
  color:rgb(34, 195, 173);
  font-weight: bold;

  padding-bottom: 30px;
}
.media .video-container{
  width: 100%;
  position: relative;
}
.media img{
  width: 100%;
  height: 200px;
}
.media .video-icon{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 58px;
  height: 58px;
  transform: translate(-50%,-50%);

}
.media .video-icon img{
  display: block;
  width:58px;
  height: 58px;

}
.media span{
  display: block;
  padding-top: 15px;
  color: #353535;
  font-size: 16px;
}
.en .media span{
  font-size: 18px;
}
.media p{
  font-size: 14px;
  line-height: 2;
  letter-spacing: 2px;
  color: #888;
  padding-bottom: 10px;
}
.en .media p{
  letter-spacing: 0;
  margin-top: 9px;
}
.video{
  padding-bottom: 100px;
}
.video ul{
  display: flex;
  //justify-content: space-between;
  flex-wrap:wrap

}

.video ul li{
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 5%;
  box-sizing: border-box;

}
.video ul li:nth-child(3n+1){
  margin-left: 0;
}
.news{
  margin-top: 30px;
}
.news ul{
  display: flex;
  //justify-content: space-between;
  flex-wrap:wrap
}

.news ul li{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 5%;
  box-sizing: border-box;
}
.news ul li:hover{
 cursor: pointer;
  //z-index: 2;
  //box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  //transform: translate3d(0,-2px,0);
  //transition: all .2s linear;
}
.news ul li:nth-child(3n+1){
  margin-left: 0;
}
@media (max-width: 680px) {
  .media{
    padding: 50px 10px;
    margin: 0 auto;
  }
  .media h4{
    font-size: 28px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    padding-bottom: 30px;
    text-align: center;
  }
  .media img{
    display: block;
    width:100%;
    height: 114px
  }
  .media span{
    display: block;
    padding-top: 0px;
    color: #353535;
    font-size: 14px;

    text-align: left;
  }
  .en .media span{
    font-size: 14px;
  }
  .media p{
    font-size: 14px;
    letter-spacing: 2px;
    color: #888;
    text-align: left;
    padding-bottom: 0px;
  }
  .en .media p{
    margin-top: 0;
  }
  .video{
    padding-bottom: 50px;
  }
  .video ul{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap
  }

  .video ul li{
    display: flex;
    flex-direction: column;
    width:48%;
    margin-top: 10px;
    margin-left: 0;

  }

  .news{
    margin-top: 30px;
  }
  .news ul{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap
  }

  .news ul li{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-left: 0;
  }

}

</style>
